<template>
	<page-container class="index" :page="page">
		<heading-image v-if="page.headingImage" type="large">
			<hero-image>
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p class="hero-title">
						{{ defaults[locale].homepage.headingImageTitle }}
					</p>
					<div v-parse-links v-html="defaults[locale].homepage.headingImageContent" />
				</div>
				<video-header v-if="page.vimeoID" :video="page.vimeoID" type="landscape" />
				<template v-else>
					<picture>
						<source
							v-if="page.headingImagePortraitWebp"
							:srcset="page.headingImagePortraitWebp"
							media="(max-width: 500px)"
							type="image/webp"
						/>
						<source
							v-if="page.headingImagePortrait"
							:srcset="page.headingImagePortrait"
							media="(max-width: 500px)"
						/>
						<source :srcset="page.headingImageWebp" type="image/webp" />
						<source :srcset="page.headingImage" />
						<img :src="page.headingImage" :alt="page.headingImageAlt" />
					</picture>
				</template>
			</hero-image>
		</heading-image>

		<div class="main-content-wrapper">
			<main-content>
				<div class="grid main-intro">
					<div class="intro-image">
						<picture>
							<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
							<source :srcset="defaults[locale].homepage.introImage" />
							<img
								:src="defaults[locale].homepage.introImage"
								:alt="defaults[locale].homepage.introImageAlt"
								loading="lazy"
							/>
						</picture>
					</div>
					<div class="intro-text">
						<div class="intro-inner">
							<h2 class="subtitle">
								{{ page.subtitle }}
							</h2>
							<h1>{{ page.title || page.header }}</h1>
							<div v-parse-links v-html="page.content" />
						</div>
					</div>
				</div>
			</main-content>
		</div>

		<promoblocks :items="promoblocksData" />

		<section v-if="defaults[locale].homepage.sectionReservationsTitle" class="section-reservations">
			<div class="row">
				<div class="columns column6 reservation-content">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.sectionReservationsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.sectionReservationsTitle }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.sectionReservationsContent" />
				</div>
				<div class="reservation-button">
					<book-button class="button book-now cta reserve-now">
						<span>{{ $t('bookNow') }}</span>
					</book-button>
				</div>
				<div class="columns column6 reservation-image">
					<picture>
						<source :srcset="defaults[locale].homepage.sectionReservationsImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.sectionReservationsImage" />
						<img loading="lazy" :src="defaults[locale].homepage.sectionReservationsImage" />
					</picture>
				</div>
			</div>
		</section>

		<section v-if="defaults[locale].homepage.promotionBackgroundImage" class="section-promotion">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.promotionsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.promotionsHeader }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.promotionsContent" />
				</div>
			</div>
			<div class="promotions-image">
				<div style="padding: 0; position: absolute; inset: 0">
					<iframe
						src="https://player.vimeo.com/video/927480851?badge=0&amp;autoplay=1&amp;muted=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;background=1"
						frameborder="0"
						allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
						style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
						title="182A7126"
					></iframe>
				</div>
			</div>
		</section>

		<contentblocks :items="page.contentblocks" class="section-contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2 class="subtitle">
							{{ defaults[locale].homepage.sectionContentblocksSubtitle }}
						</h2>
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
					</div>
				</div>
			</template>
		</contentblocks>

		<section v-if="defaults[locale].homepage.contactHeader" class="content-section section-contact contact-info">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.contactSubtitle }}
					</h2>
					<h2>
						{{ defaults[locale].homepage.contactHeader }}
					</h2>
					<div v-parse-links v-html="defaults[locale].homepage.contactContent" />
				</div>
			</div>
			<div class="row">
				<div class="columns column4">
					<p>
						<strong>{{ defaults[locale].website.restaurantName }}</strong
						><br />
						{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}<br />
						{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}<br />
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
							{{ defaults[locale].website.phone }} </a
						><br />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon class="icon" icon="fal fa-envelope" size="1x" />
							{{ defaults[locale].website.email }}
						</a>
					</p>
				</div>
				<div class="columns column8">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.7659088616188!2d4.89755507694073!3d52.37466454697925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609b9a7cbcd0b%3A0xf4e99cc9901deee9!2sMata%20Hari!5e0!3m2!1sen!2snl!4v1707921359616!5m2!1sen!2snl"
						width="100%"
						height="450"
						frameborder="0"
						style="border: 0"
						allowfullscreen=""
						aria-hidden="false"
						tabindex="0"
					/>
				</div>
			</div>
		</section>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

onMounted(() => {
	window.FT && window.FT.widgets.current.parse();
});

if (!defaults.value) {
	await fetchDefaults();
}

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.main-content-wrapper {
	background: var(--cta-color);
	position: relative;

	.content-section,
	:deep(.content-section) {
		background: none;
		padding: 75px 0;
	}

	:deep(.row),
	.row {
		max-width: 100%;
		padding: 0;
	}
}

.hero-content {
	position: absolute;
	top: calc(50% - 50px);
	display: flex;
	flex-flow: column nowrap;
	text-transform: uppercase;
	color: #fff;
	max-width: 1280px;
	text-align: center;
	z-index: 5;
	left: 20px;
	right: 20px;
	margin: auto;
	transform: translateY(calc(-50% + 100px));
	animation: fade-from-bottom 1.5s ease;

	.hero-title {
		width: 100%;
		font-weight: 400;
		color: var(--footer-color);
		font-size: 120px;
		margin-bottom: 10px;
		line-height: normal;
		font-family: var(--secondary-heading-font-family);
	}

	:deep(> *),
	p,
	:deep(p) {
		max-width: 700px;
		font-size: 30px;
		margin: 0 auto 10px;
		line-height: 36px;
		font-weight: 200;
		color: #fff;
	}
}

.main-intro {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;

	.intro-text {
		position: relative;
		z-index: 1;
		padding: 150px 150px 150px 300px;
		background: url('~/assets/images/introblock-bg.png') no-repeat center center #1d1d1b;
		background-size: cover;
		width: calc(50vw + 200px);

		.intro-inner {
			max-width: 580px;
			color: rgba(255 255 255 / 80%);

			h1,
			:deep(h1) {
				color: var(--footer-color);
			}
		}
	}

	.intro-image {
		z-index: 2;
		position: absolute;
		inset: 150px 50vw 150px calc(50vw - 640px);

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.section-reservations {
	padding: 100px 0;
	background: url('~/assets/images/reservations-section-bg-pattern.png') no-repeat center center
		var(--primary-background-color);
	background-size: cover;
	position: relative;
	min-height: 580px;

	.reservation-image {
		position: absolute;
		width: 50vw;
		top: 50px;
		right: 0;
		bottom: 50px;
		z-index: 1;
	}

	.reservation-content {
		padding-bottom: 100px;
		position: relative;
		z-index: 1;
	}

	.reservation-button {
		position: absolute;
		bottom: 100px;
		left: 0;
		right: 0;
		z-index: 2;
		margin: 0 auto;
		background: var(--cta-color);
		padding: 35px 30px;
		width: 240px;
		max-width: 90%;

		.button,
		:deep(.button) {
			color: var(--cta-color);
			background: #fff;
			width: 100%;
			padding: 20px 0;
			text-align: center;

			&:hover {
				background: var(--cta-color-hover);
				color: var(--cta-color);
			}
		}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.section-promotion {
	background: var(--secondary-background-color);
	margin: 0;
	padding: 150px 25px;
	overflow: auto;
	clip-path: inset(0);
	position: relative;

	&::after {
		background: rgb(0 0 0 / 40%);
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		content: '';
	}

	.row {
		position: relative;
		z-index: 10;
		color: #fff;
		overflow: hidden;
		max-width: 800px;
	}

	.promotions-image {
		overflow: hidden;

		> div {
			overflow: hidden !important;
		}

		iframe {
			width: 100% !important;
			height: 180vw !important;
			position: absolute !important;
			margin-top: -75vw;
			inset: 0 !important;
		}
	}

	h2:not(.subtitle) {
		color: #fff;
	}
}

.section-contentblocks {
	.blocks-header {
		margin-bottom: 70px;

		h2:not(.subtitle),
		h3,
		:deep(h2:not(.subtitle)),
		:deep(h3) {
			color: #fff;
		}
	}
}

.section-contact {
	background: var(--primary-background-color);
	position: relative;
	color: var(--footer-color);
	padding: 100px 0 0;
	margin: 0 0 50px;

	h2:not(.subtitle),
	h3 {
		color: var(--footer-color);
	}

	&::before {
		content: '';
		display: block;
		background: url('~/assets/images/contact-info-bg-image.png') no-repeat center center;
		background-size: cover;
		position: absolute;
		inset: 0 0 75px;
		z-index: 1;
	}

	.row {
		position: relative;
		z-index: 2;

		&:first-child {
			padding-bottom: 50px;
		}
	}

	a {
		color: var(--footer-color);

		&:hover {
			color: #fff;
		}
	}
}

@keyframes fade-from-bottom {
	0% {
		opacity: 0;
		transform: translateY(calc(-30% + 100px));
	}

	66% {
		opacity: 0;
	}

	100% {
		opacity: 1;
		transform: translateY(calc(-50% + 100px));
	}
}

@media (max-width: 1080px) {
	.hero-content {
		max-width: 650px;

		.hero-title {
			font-size: 80px;
		}

		p,
		:deep(p) {
			font-size: 24px;
			line-height: 32px;
		}
	}

	.main-intro {
		display: flex;
		flex-flow: row wrap;
		align-items: center;

		.intro-text {
			margin: -100px auto 0;
			width: 94%;
			padding: 160px 30px 50px;
		}

		.intro-image {
			position: relative;
			inset: auto;
			width: 70%;
			overflow: hidden;
			margin: 0 auto;
		}
	}
}

@media (max-width: 880px) {
	.main-content-wrapper {
		.content-section,
		:deep(.content-section) {
			padding: 50px 0;
		}
	}

	.section-promotions {
		padding: 125px 25px;
	}

	.section-promotion {
		.promotions-image {
			position: absolute;
			inset: 0;

			iframe {
				width: 100% !important;
				height: 240vw !important;
				position: absolute !important;
				margin-top: -40vw;
				inset: 0 !important;
			}
		}
	}

	.section-contact {
		text-align: center;

		.columns {
			width: 100%;
		}
	}

	.section-reservations {
		.reservation-image {
			position: relative;
			inset: auto;
			width: 100%;
		}

		.reservation-content {
			padding-bottom: 25px;
		}

		.reservation-button {
			position: relative;
			inset: auto;
			margin: 0 auto -60px;
		}
	}

	.hero-content {
		font-size: 15px;
		padding: 0 10px;

		.hero-title {
			font-size: 52px;
		}

		p,
		:deep(p) {
			font-size: 20px;
			line-height: 28px;
		}
	}
}
</style>
